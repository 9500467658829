import {createTheme} from '@mui/material/styles';
import {ruRU} from './vendor/locales/x-data-grid/ruRU';

const theme = createTheme({
    palette: {
        success: {
            main: '#388E3C',
        },
        error: {
            main: '#D32F2F',
        },
    },
    typography: {
        fontFamily: 'Montserrat Variable, sans-serif',
    },
    ".MuiDataGrid-cell": {
        border: "1px solid red",
        borderRadius: "5px 5px 0 0"
    },

}, ruRU);

export default theme
