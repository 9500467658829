import React, { useEffect, useRef } from 'react';
import IMask from 'imask';
import { TextField } from '@mui/material';

const MaskedInput = ({ mask, ...props }) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            IMask(inputRef.current, {
                mask: mask,
            });
        }
    }, [mask]);

    return <TextField inputRef={inputRef} {...props} />;
};

export default MaskedInput;
