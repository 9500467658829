import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialState = {
    name: '',
    inn: '',
    last_name: '',
    first_name: '',
    middle_name: '',
    phone: '',
    email: '',
    token: '',
    open: false,
    loading: false,
    error: null,
};

export const createDemoSiteRequest = createAsyncThunk(
    'demoSite/createDemoSiteRequest',
    async (csrfToken, { getState, rejectWithValue }) => {
        const state = getState(); // Получаем текущее состояние Redux
        const { name, inn, last_name, first_name, middle_name, phone, email, token } = state.demoSite;

        if (!token) {
            return rejectWithValue('Пожалуйста, пройдите капчу');
        }

        // Тело запроса
        const body = {
            demo_site: {
                name,
                inn,
                last_name,
                first_name,
                middle_name,
                phone,
                email,
                token,
            }
        };

        try {
            const response = await fetch('/create_demo_site', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrfToken
                },
                body: JSON.stringify(body),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Ошибка при создании демо-сайта');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message || 'Произошла ошибка');
        }
    }
);

// Создание слайса
const demoSiteSlice = createSlice({
    name: 'demoSite',
    initialState,
    reducers: {
        setName: (state, action) => {
            state.name = action.payload;
        },
        setInn: (state, action) => {
            state.inn = action.payload;
        },
        setLastName: (state, action) => {
            state.last_name = action.payload;
        },
        setFirstName: (state, action) => {
            state.first_name = action.payload;
        },
        setMiddleName: (state, action) => {
            state.middle_name = action.payload;
        },
        setPhone: (state, action) => {
            state.phone = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setOpen: (state, action) => {
            state.open = action.payload;
        },
        resetForm: (state) => {
            Object.assign(state, initialState);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createDemoSiteRequest.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createDemoSiteRequest.fulfilled, (state, action) => {
                Object.assign(state, initialState);
                toast.success('Заявка принята. Ожидайте письма с данными сайта.');
            })
            .addCase(createDemoSiteRequest.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Произошла ошибка при отправке запроса';
                toast.error(state.error);
            });
    },
});

// Экспорт действий
export const {
    setName,
    setInn,
    setLastName,
    setFirstName,
    setMiddleName,
    setPhone,
    setEmail,
    setToken,
    setOpen,
    resetForm, // Добавляем действие для сброса формы
} = demoSiteSlice.actions;

// Экспорт редюсера
export default demoSiteSlice.reducer;
