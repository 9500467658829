import { configureStore } from '@reduxjs/toolkit'
import demoSiteReducer from "./redux/demoSiteSlice";

const apartmentStore = configureStore({
    reducer: {
        demoSite: demoSiteReducer,
    },
})

export default apartmentStore;
