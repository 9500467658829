import {createRoot} from "react-dom/client";
import React from "react";

export default function reactRender(components) {
    const parseProps = (element) => {
        const propsString = element.getAttribute('data-react-props');
        try {
            return propsString ? JSON.parse(propsString) : {};
        } catch (error) {
            console.error('Ошибка при парсинге пропсов:', error);
            return {};
        }
    };

    const renderComponent = (element) => {
        const componentName = element.dataset.reactClass;
        const ReactComponent = components[componentName];

        if (!ReactComponent) {
            console.warn(`Компонент "${componentName}" не найден.`);
            return;
        }

        const props = parseProps(element);
        const root = createRoot(element);
        root.render(<ReactComponent {...props} />);
    };


    const initializeReactComponents = () => {
        const reactComponentElements = document.querySelectorAll('[data-react-class]');
        reactComponentElements.forEach(renderComponent);
    };


    document.addEventListener('DOMContentLoaded', initializeReactComponents);
}
