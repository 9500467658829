import DemoSiteRequestRoot from "../../../javascript/react/components/demo_site_request/DemoSiteRequestRoot";
import reactRender from "../shared/react_render";

const components = {
    DemoSiteRequestRoot
};

reactRender(components)



