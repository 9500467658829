import * as React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import { TextField } from "@mui/material";
import MaskedInput from "../imask/MaskedInput";
import { SmartCaptcha } from '@yandex/smart-captcha';
import colors from "../../colors";
import { useDispatch, useSelector } from "react-redux";
import {
    setName,
    setInn,
    setLastName,
    setFirstName,
    setMiddleName,
    setPhone,
    setEmail,
    setToken,
    setOpen
} from "../../apartment/redux/demoSiteSlice";
import { createDemoSiteRequest } from "../../apartment/redux/demoSiteSlice";
import { useForm, Controller } from 'react-hook-form';

const Content = styled.div`
    position: relative;
    max-height: 90vh;
    @media (max-width: 768px) {
        max-height: 80vh;
    }
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    background-color: white;
    border-radius: 6px;
    max-width: 450px;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
`;

const Title = styled.span`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding-top: 10px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const CloseButton = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 24px;
    text-align: right;
    cursor: pointer;
    color: ${colors.red};
`;

const CustomButton = styled(Button)`
    background: linear-gradient(to bottom right, #EE3897, #1E90FF);
    border: 0;
    border-radius: 12px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    line-height: 2.5;
    outline: transparent;
    padding: 0 1rem;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .2s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;

    &:hover {
        filter: brightness(0.9);
    }
`;

export default function DemoSiteRequest({ sitekey, csrfToken }) {
    const { name, inn, last_name, first_name, middle_name, phone, email, token, open } = useSelector(apartmentStore => apartmentStore.demoSite);
    const dispatch = useDispatch();

    const { control, handleSubmit, reset, formState: { errors }, setError, clearErrors } = useForm({
        defaultValues: {
            name: name,
            inn: inn,
            last_name: last_name,
            first_name: first_name,
            middle_name: middle_name,
            phone: phone,
            email: email
        }
    });

    const handleOpen = () => dispatch(setOpen(true));
    const handleClose = () => dispatch(setOpen(false));
    const handleToken = (token) => {
        dispatch(setToken(token));
        clearErrors('token'); // Очищаем ошибку, если token получен
    };

   React.useEffect(() => {
        reset({
            name: name,
            inn: inn,
            last_name: last_name,
            first_name: first_name,
            middle_name: middle_name,
            phone: phone,
            email: email,
        });
    }, [name, inn, last_name, first_name, middle_name, phone, email, reset]);

    const onSubmit = (data) => {
        if (!token) {
            setError('token', {
                type: 'manual',
                message: 'Пожалуйста, пройдите капчу',
            });
            return;
        }

        dispatch(setName(data.name));
        dispatch(setInn(data.inn));
        dispatch(setLastName(data.last_name));
        dispatch(setFirstName(data.first_name));
        dispatch(setMiddleName(data.middle_name));
        dispatch(setEmail(data.email));
        dispatch(setPhone(data.phone));


        dispatch(createDemoSiteRequest(csrfToken));
    };

    return (
        <>
            <CustomButton onClick={handleOpen}>Создать демо-сайт</CustomButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Content>
                    <CloseButton onClick={handleClose}><i className="fa-solid fa-circle-xmark"></i></CloseButton>
                    <Title id="modal-title">Заявка на демо-сайт</Title>
                    <div id="modal-description">
                        <p>
                            После заполнения формы будет развернут новый демо-сайт,
                            в течение минуты на почту будут отправлены учетные данные для доступа.
                        </p>
                        <p>
                            Поля, отмечанные * обязательны для заполнения.
                        </p>
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            name="name"
                            control={control}
                            rules={{ required: 'Название не заполнено' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    size="small"
                                    fullWidth
                                    label="Название*"
                                    variant="outlined"
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                />
                            )}
                        />
                        <Controller
                            name="inn"
                            control={control}
                            rules={{ required: 'ИНН не заполнено' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    size="small"
                                    fullWidth
                                    label="ИНН*"
                                    variant="outlined"
                                    error={!!errors.inn}
                                    helperText={errors.inn?.message}
                                />
                            )}
                        />
                        <Controller
                            name="first_name"
                            control={control}
                            rules={{ required: 'Имя не заполнено' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    size="small"
                                    fullWidth
                                    label="Имя*"
                                    variant="outlined"
                                    error={!!errors.first_name}
                                    helperText={errors.first_name?.message}
                                />
                            )}
                        />
                        <Controller
                            name="middle_name"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    size="small"
                                    fullWidth
                                    label="Отчество"
                                    variant="outlined"
                                />
                            )}
                        />
                        <Controller
                            name="last_name"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    size="small"
                                    fullWidth
                                    label="Фамилия"
                                    variant="outlined"
                                />
                            )}
                        />
                        <Controller
                            name="email"
                            control={control}
                            rules={{ required: 'Email не заполнен', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Некорректный email' } }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    size="small"
                                    fullWidth
                                    label="Email*"
                                    variant="outlined"
                                    error={!!errors.email}
                                    helperText={errors.email?.message}
                                />
                            )}
                        />
                        <Controller
                            name="phone"
                            control={control}
                            rules={{ required: 'Телефон не заполнен', pattern: { value: /^\+7-\d{3}-\d{3}-\d{2}-\d{2}$/i, message: 'Некорректный телефон' } }}
                            render={({ field }) => (
                                <MaskedInput
                                    {...field}
                                    size="small"
                                    label="Телефон*"
                                    mask="+{7}-000-000-00-00"
                                    placeholder="+7-999-999-99-99"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.phone}
                                    helperText={errors.phone?.message}
                                />
                            )}
                        />
                        <SmartCaptcha sitekey={sitekey} onSuccess={handleToken} language='ru' />
                        {errors.token && <p style={{ color: 'red' }}>{errors.token.message}</p>}
                        <Button type="submit" color='success' variant="contained">Отправить</Button>
                    </Form>
                </Content>
            </Modal>
        </>
    );
}
