import * as React from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import theme from "../../theme";
import {Provider} from "react-redux";
import apartmentStore from "../../apartment/apartmentStore";
import DemoSiteRequest from "./DemoSiteRequest";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DemoSiteRequestRoot({sitekey, csrfToken}) {
    return (
        <Provider store={apartmentStore}>
            <ThemeProvider theme={theme}>
                <ToastContainer />
                <DemoSiteRequest sitekey={sitekey} csrfToken={csrfToken}/>
            </ThemeProvider>
        </Provider>
    );
}
